import React from 'react'
import './about.css'
import ME from '../../assets/IMG_aboutMe.jpg'
import { FaAward } from "react-icons/fa6";
import { GiGraduateCap } from "react-icons/gi";


const About = () => {
  return (
    <section id='about'>
    <h5>Get To Know</h5>
    <h2>About Me</h2>

    <div className="container about__container">
      <div className="about__me">
        <div className="about__me-image">
          <img src={ME} alt="About Image" />
        </div>
      </div>

      <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>5 years of experience in environmental projects, community risk management, and database administration, 
                as well as, to a lesser extent, in web application development</small>
            </article>

            <article className='about__card'>
              <GiGraduateCap className='about__icon'/>
              <h5>Studies</h5>
              <small>Bachelor's Honor Degree in Geology, University of Costa Rica</small>
              <div>&nbsp;</div> {/* Espacio */}
              <small>Advanced Computer Systems Engineering Student (79%), University Fidélitas</small>
            </article>
          </div>

          <p>
              I love programming, data analysis, and continuous learning. Because of this, I am always learning new things and staying updated. 
              I am looking for a position where I can apply my full-stack development skills and database experience to maintain and build applications.
              I have 5 years of experience in environmental projects, community risk management, and database
              administration, working at institutions such as the Red Sismológica Nacional and OVSICORI. 
          </p>

          <a href="#contact" className='btn btn-primary'>Contact</a>
        </div>
    </div>
    </section>
  )
}

export default About