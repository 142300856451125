import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Frontend User For Company LubricentroRyM, usign React, Bootstrap, Githubpages",
    github: "https://github.com/bacosta30762/ProyectoFinalFidelitasUser.git",
    demo: "https://bacosta30762.github.io/ProyectoFinalFidelitasUser/",
  },
  {
    id: 2,
    image: IMG2,
    title: "Frontend Admin For Company LubricentroRyM, usign React, Bootstrap, Githubpages",
    github: "https://github.com/bacosta30762/ProyectoFinalFidelitas.git",
    demo: "https://bacosta30762.github.io/ProyectoFinalFidelitas/",
  },
  {
    id: 3,
    image: IMG3,
    title: "Backend For Company LubricentroRyM, usign C#, SOLID, Clean Architecture, Azure",
    github: "https://github.com/bacosta30762/ProyectoFinalFidelitas_Backend.git",
    demo: "https://apirymlubricentro-dddjebcxhyf6hse7.centralus-01.azurewebsites.net/",
  },
  {
    id: 4,
    image: IMG4,
    title: "Proyect for Users and view flights using C#, SCSS, JavaScript, with third party APi",
    github: "https://github.com/bacosta30762/PrograAvanzadaWeb.git",
    demo: "https://github.com/bacosta30762/PrograAvanzadaWeb.git",
  },
  {
    id: 5,
    image: IMG5,
    title: "Proyect Ticket Manager using C#, CSS, JavaScript, Bootstrap",
    github: "https://github.com/bacosta30762/TicketManagerPrograA.git",
    demo: "https://github.com/bacosta30762/TicketManagerPrograA.git",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt="title" />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  Github
                </a>
                <a href={demo} className="btn-primary" target="_blank">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
